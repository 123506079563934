import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
// import loadable from "@loadable/component";
import { Text } from "cms-caravela";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { useSite } from "../../hooks/useSite";
import { useTheme } from "../../contexts/ThemeContext";

const Container = dynamic(() =>
  import("./styles").then((mod) => mod.Container)
);

export default function Header() {
  const { handleOpen, config } = useSite();
  const { currentTheme } = useTheme();
  const [themeLight, setThemeLight] = useState(true);
  const router = useRouter();
  const { pathname } = router;

  const path = pathname.replace(/\//, "");

  useEffect(() => {
    const rotas = ["servicos", "portifolio", "blog"];

    if (rotas.includes(path) && !currentTheme.isDark) {
      setThemeLight(false);
    } else {
      setThemeLight(true);
    }
  }, [path, currentTheme.isDark]);

  return (
    <Container>
      <div className="container">
        <div className="container-logo-header">
          <Link href="/">
            {themeLight ? (
              <Image
                src="/img/icone/icone-logo.svg"
                width={207}
                height={52}
                alt="logo caravela"
                priority
              />
            ) : (
              <Image
                src="/img/icone/logoDark.svg"
                width={207}
                height={52}
                alt="logo caravela"
                priority
              />
            )}
          </Link>
        </div>

        <div className="social-header">
          <Link
            href={config?.social?.instagram || ""}
            rel="noopener noreferrer"
            target="_blank"
          >
            {themeLight ? (
              <Image
                src="/img/icone/icone-insta.svg"
                width={19}
                height={19}
                alt="Instagram"
                loading="eager"
                priority
              />
            ) : (
              <Image
                src="/img/icone/insta-dark.svg"
                width={19}
                height={19}
                alt="Instagram"
                loading="eager"
                priority
              />
            )}
          </Link>
          <Link
            href={config?.social?.facebook || ""}
            rel="noopener noreferrer"
            target="_blank"
          >
            {themeLight ? (
              <Image
                src="/img/icone/face.svg"
                alt="FaceBook"
                loading="eager"
                priority
                width={19}
                height={19}
              />
            ) : (
              <Image
                src="/img/icone/faceDark.svg"
                alt="FaceBook"
                loading="eager"
                priority
                width={19}
                height={19}
              />
            )}
          </Link>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href={
              config?.telefone
                ? "https://wa.me/55" + config?.telefone.replace(/\D/g, "")
                : ""
            }
          >
            {themeLight ? (
              <Image
                src="/img/icone/whats.svg"
                alt="whatsapp"
                loading="eager"
                priority
                width={19}
                height={19}
              />
            ) : (
              <Image
                src="/img/icone/whatsDark.svg"
                alt="whatsapp"
                loading="eager"
                priority
                width={19}
                height={19}
              />
            )}
          </Link>
        </div>

        <div className="toogle" onClick={() => handleOpen("menu")}></div>
      </div>
    </Container>
  );
}
